.p-multiselect-label {
    display: flex;
    gap: 1rem;
    max-width: 300px;
}

.p-datepicker table td > span {
    border-radius: 50%;
}
.p-calendar .p-inputtext {
    color: #FFF;
    background-color: transparent;
    outline: 0;
    border: none;
}
.p-calendar {
    display: flex;
    flex-direction: row-reverse;
}
.p-calendar-w-btn-right .p-datepicker-trigger {
    /* background-color: transparent; */
    border: none;
    border-top-right-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 5px;
}